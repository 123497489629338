export const formulas = {
  ref3: {
    formula: (ref1, ref2) => {
      return ref1 ? ref2 / ref1 : 0;
    },
    values: ["ref1", "ref2"]
  },
  ref6: {
    formula: (ref4, ref5) => {
      return ref4 ? ref5 / ref4 : 0;
    },
    values: ["ref4", "ref5"]
  },
  refcg3: {
    formula: (ref4, refcg1, refcg2) => {
      return ref4 - refcg1 - refcg2;
    },
    values: ["ref4", "refcg1", "refcg2"]
  },
  refcg10: {
    formula: (refcg5, refcg8) => {
      return refcg8 / refcg5;
    },
    values: ["refcg5", "refcg8"]
  },
  ref8: {
    formula: (ref3, ref5, ref6, ref7) => {
      if (ref5 === 0) return ref3 ? ((ref3 - ref7) / ref3) * 100 : 0;
      else return ((ref6 - ref7) / ref6) * 100;
    },
    values: ["ref3", "ref5", "ref6", "ref7"]
  },
  ref9: {
    formula: (ref2, ref5, ref8, ref18, ref20, ref49, ref50, ref51, ref52, ref53, ref54, ref55, ref56) => {
      return ((ref5 + ref2 - (ref18 + ref20 + ref49 + ref50 + ref51 + ref52 + ref53 + ref54 + ref55 + ref56)) * ref8) / 100;
    },
    values: ["ref2", "ref5", "ref8", "ref18", "ref20", "ref49", "ref50", "ref51", "ref52", "ref53", "ref54", "ref55", "ref56"]
  },
  ref9cg: {
    formula: (
      ref2,
      ref5,
      ref8,
      ref18,
      ref20,
      ref49,
      ref50,
      ref51,
      ref52,
      ref53,
      ref54,
      ref55,
      ref56,
      ref60,
      ref68,
      ref73,
      ref117,
      ref118,
      ref119,
      ref120,
      ref121,
      ref122,
      ref123,
      ref124,
      ref125,
      ref164,
      ref181,
      ref182,
      ref183,
      ref184,
      ref185,
      ref186,
      ref187,
      ref188,
      ref189,
      ref190,
      ref191
    ) => {
      return (
        ((ref5 +
          ref2 -
          (ref18 + ref20 + ref49 + ref50 + ref51 + ref52 + ref53 + ref54 + ref55 + ref56) -
          (ref68 +
            ref73 +
            ref117 +
            ref118 +
            ref119 +
            ref120 +
            ref121 +
            ref122 +
            ref123 +
            ref124 +
            ref125 +
            ref164 +
            ref181 +
            ref182 +
            ref183 +
            ref184 +
            ref185 +
            ref186 +
            ref187 +
            ref188 +
            ref189 +
            ref190 +
            ref191 +
            ref60)) *
          ref8) /
        100
      );
    },
    values: [
      "ref2",
      "ref5",
      "ref8",
      "ref18",
      "ref20",
      "ref49",
      "ref50",
      "ref51",
      "ref52",
      "ref53",
      "ref54",
      "ref55",
      "ref56",
      "ref60",
      "ref68",
      "ref73",
      "ref117",
      "ref118",
      "ref119",
      "ref120",
      "ref121",
      "ref122",
      "ref123",
      "ref124",
      "ref125",
      "ref164",
      "ref181",
      "ref182",
      "ref183",
      "ref184",
      "ref185",
      "ref186",
      "ref187",
      "ref188",
      "ref189",
      "ref190",
      "ref191"
    ]
  },
  ref13: {
    formula: (ref10, ref11, ref12) => {
      return (1 - (ref10 + ref11 + ref12) / 100) * 100;
    },
    values: ["ref10", "ref11", "ref12"]
  },
  ref15: {
    formula: () => 0,
    values: []
  },
  ref17: {
    formula: (ref14, ref16, ref45, ref48) => {
      return ((ref48 - ref45) * (ref16 - ref14)) / 100;
    },
    values: ["ref14", "ref16", "ref45", "ref48"]
  },
  ref18: {
    formula: (ref6, ref17) => {
      return ref17 * ref6;
    },
    values: ["ref6", "ref17"]
  },
  ref19: {
    formula: (ref14, ref15, ref16, ref45, ref48) => {
      return ((ref48 - ref45) * (ref16 - ref15) - (ref48 - ref45) * (ref16 - ref14)) / 100;
    },
    values: ["ref14", "ref15", "ref16", "ref45", "ref48"]
  },
  ref20: {
    formula: (ref6, ref19) => {
      return ref19 * ref6;
    },
    values: ["ref6", "ref19"]
  },
  ref22: {
    formula: (ref1, ref2, ref5, ref10, ref21) => {
      ref10 = ref10 / 100;
      return ref21 + ref1 ? (ref5 * ref10 + ref2) / (ref21 + ref1) : 0;
    },
    values: ["ref1", "ref2", "ref5", "ref10", "ref21"]
  },
  ref23: {
    formula: (ref24, ref25, ref26, ref27, ref28) => {
      return (1 - (ref24 + ref25 + ref26 + ref27 + ref28) / 100) * 100;
    },
    values: ["ref24", "ref25", "ref26", "ref27", "ref28"]
  },
  ref34: {
    formula: (ref1, ref21, ref25, ref29) => {
      try {
        return ((ref21 + ref1) * ref25) / ref29 / 100;
      } catch (error) {
        return 0;
      }
    },
    values: ["ref1", "ref21", "ref25", "ref29"]
  },
  ref35: {
    formula: (ref1, ref21, ref26, ref30) => {
      try {
        return ((ref21 + ref1) * ref26) / ref30 / 100;
      } catch (error) {
        return 0;
      }
    },
    values: ["ref1", "ref21", "ref26", "ref30"]
  },
  ref36: {
    formula: (ref1, ref21, ref27, ref31) => {
      try {
        return ((ref21 + ref1) * ref27) / ref31 / 100;
      } catch (error) {
        return 0;
      }
    },
    values: ["ref1", "ref21", "ref27", "ref31"]
  },
  ref44: {
    formula: (ref40, ref41, ref42) => {
      return (24 * 0.18 * ref40 * ref42 + 24 * 0.23 * (4 * Math.sqrt(ref40) * ref41) * ref42) / 1000000;
    },
    values: ["ref40", "ref41", "ref42"]
  },
  ref45: {
    formula: (ref4, ref11, ref44) => {
      const chgRef11 = ref11 / 100;
      if (chgRef11 === 0) return 0;
      else if (ref44 < chgRef11 * ref4) return ref44;
      else return chgRef11 * ref4;
    },
    values: ["ref4", "ref11", "ref44"]
  },
  ref46: {
    formula: (ref4, ref11, ref43, ref44) => {
      const chgRef11 = ref11 / 100;
      if (chgRef11 === 0) return ref43 ? ref44 / ref43 : 0;
      else if (ref44 < chgRef11 * ref4) return 0;
      else return (ref44 - chgRef11 * ref4) / ref43;
    },
    values: ["ref4", "ref11", "ref43", "ref44"]
  },
  ref47: {
    formula: (ref6, ref22, ref45, ref46) => {
      return ref45 * ref6 + ref46 * ref22;
    },
    values: ["ref6", "ref22", "ref45", "ref46"]
  },
  ref48: {
    formula: (ref4, ref10, ref21, ref29, ref30, ref31, ref37, ref38, ref39, ref45, ref46) => {
      const chgRef10 = ref10 / 100;
      if (ref21 === 0) return ref45;
      else if (ref21 < ref29 * ref37 + ref30 * ref38 + ref46) return ref4 * chgRef10 + ref45;
      else return (ref29 * ref37 + ref30 * ref38 + ref31 * ref39 + ref46) / ((ref4 * chgRef10) / ref21) / 1000000 + ref45;
    },
    values: ["ref21", "ref45", "ref29", "ref37", "ref30", "ref38", "ref46", "ref4", "ref10", "ref31", "ref39"]
  },
  ref49: {
    formula: (ref22, ref29, ref34, ref37, ref51) => {
      return (ref34 * ref22 - ref37 * ref22) * ref29 - ref51 ?? 0;
    },
    values: ["ref34", "ref22", "ref37", "ref29", "ref51"]
  },
  ref50: {
    formula: (ref22, ref30, ref35, ref38, ref52) => {
      return (ref35 * ref22 - ref38 * ref22) * ref30 - ref52 ?? 0;
    },
    values: ["ref35", "ref22", "ref38", "ref30", "ref52"]
  },
  ref51: {
    formula: (ref22, ref29, ref32, ref34, ref37) => {
      return (ref34 * levelWiseValue[`level${ref32}`] * ref22 - ref37 * ref22) * ref29;
    },
    values: ["ref34", "ref32", "ref22", "ref37", "ref29"]
  },
  ref52: {
    formula: (ref22, ref30, ref33, ref35, ref38) => {
      return (ref35 * levelWiseValue[`level${ref33}`] * ref22 - ref38 * ref22) * ref30;
    },
    values: ["ref22", "ref30", "ref33", "ref35", "ref38"]
  },
  ref53: {
    formula: (ref22, ref31, ref36, ref39) => {
      return (ref36 * ref22 - ref39 * ref22) * ref31 ?? 0;
    },
    values: ["ref36", "ref22", "ref39", "ref31"]
  },
  ref54: {
    formula: (ref5, ref12) => (ref5 * ref12) / 100,
    values: ["ref5", "ref12"]
  },
  ref55: {
    formula: (ref1, ref5, ref11, ref21, ref22, ref24, ref47) => {
      return (((ref21 + ref1) * ref24) / 100) * ref22 + (ref5 * ref11) / 100 - ref47;
    },
    values: ["ref1", "ref5", "ref11", "ref21", "ref22", "ref24", "ref47"]
  },
  ref56: {
    formula: (ref1, ref21, ref22, ref23) => {
      return ((ref21 + ref1) * ref23 * ref22) / 100;
    },
    values: ["ref21", "ref1", "ref23", "ref22"]
  },
  ref57: {
    formula: (refcg4, refcg5, refcg6) => {
      return refcg4 + refcg5 - refcg6;
    },
    values: ["refcg4", "refcg5", "refcg6"]
  },
  ref58: {
    formula: (refcg7, refcg8, refcg9) => {
      return refcg7 + refcg8 - refcg9;
    },
    values: ["refcg7", "refcg8", "refcg9"]
  },
  ref59: {
    formula: (ref57, ref58) => {
      return ref58 / ref57 ?? 0;
    },
    values: ["ref58", "ref57"]
  },
  ref61: {
    formula: (ref57, ref58, ref60) => {
      return ref57 ? (ref58 - ref60) / ref57 : 0;
    },
    values: ["ref58", "ref57", "ref60"]
  },
  ref63cg: {
    formula: (refcg10, ref62) => ((refcg10 - ref62) / refcg10) * 100 ?? 0,
    values: ["refcg10", "ref62"]
  },

  ref63: {
    formula: (ref61, ref62) => ((ref61 - ref62) / ref61) * 100 ?? 0,
    values: ["ref61", "ref62"]
  },
  ref64: {
    formula: (
      ref58,
      ref60,
      ref63,
      ref68,
      ref73,
      ref117,
      ref118,
      ref119,
      ref120,
      ref121,
      ref122,
      ref123,
      ref124,
      ref125,
      ref164,
      ref181,
      ref182,
      ref183,
      ref184,
      ref185,
      ref186,
      ref187,
      ref188,
      ref189,
      ref190,
      ref191
    ) => {
      return (
        ((ref58 -
          (ref68 +
            ref73 +
            ref117 +
            ref118 +
            ref119 +
            ref120 +
            ref121 +
            ref122 +
            ref123 +
            ref124 +
            ref125 +
            ref164 +
            ref181 +
            ref182 +
            ref183 +
            ref184 +
            ref185 +
            ref186 +
            ref187 +
            ref188 +
            ref189 +
            ref190 +
            ref191 +
            ref60)) *
          ref63 ?? 0) / 100
      );
    },
    values: [
      "ref58",
      "ref60",
      "ref63",
      "ref68",
      "ref73",
      "ref117",
      "ref118",
      "ref119",
      "ref120",
      "ref121",
      "ref122",
      "ref123",
      "ref124",
      "ref125",
      "ref164",
      "ref181",
      "ref182",
      "ref183",
      "ref184",
      "ref185",
      "ref186",
      "ref187",
      "ref188",
      "ref189",
      "ref190",
      "ref191"
    ]
  },
  ref68: {
    formula: (ref61, ref65, ref66, ref67) => {
      return ref65 * ref61 * (1 - ref67 / ref66) ?? 0;
    },
    values: ["ref65", "ref61", "ref67", "ref66"]
  },
  ref70: {
    formula: (ref69, ref74) => ref69 / ref74 ?? 0,
    values: ["ref69", "ref74"]
  },
  ref72: {
    formula: (ref70, ref71, ref116) => {
      return ref116 * (1 - ref71 / ref70) ?? 0;
    },
    values: ["ref116", "ref71", "ref70"]
  },
  ref73: {
    formula: (ref72, ref75) => ref72 * ref75 ?? 0,
    values: ["ref72", "ref75"]
  },
  ref75: {
    formula: (ref61, ref69, ref74) => {
      return (ref69 * ref61) / ref74 ?? 0;
    },
    values: ["ref69", "ref61", "ref74"]
  },
  ref77: {
    formula: (ref74, ref76) => (ref74 * (100 - ref76)) / 100,
    values: ["ref74", "ref76"]
  },
  ref86: {
    formula: (ref78, ref79, ref80, ref81, ref82, ref83, ref84, ref85) => {
      return 100 * (1 - (ref78 + ref79 + ref80 + ref81 + ref82 + ref83 + ref84 + ref85) / 100);
    },
    values: ["ref78", "ref79", "ref80", "ref81", "ref82", "ref83", "ref84", "ref85"]
  },
  ref87: {
    formula: (ref77, ref78) => {
      return (ref77 * ref78) / 100;
    },
    values: ["ref77", "ref78"]
  },
  ref88: {
    formula: (ref77, ref79) => {
      return (ref77 * ref79) / 100;
    },
    values: ["ref77", "ref79"]
  },
  ref89: {
    formula: (ref77, ref80) => {
      return (ref77 * ref80) / 100;
    },
    values: ["ref77", "ref80"]
  },
  ref90: {
    formula: (ref77, ref81) => {
      return (ref77 * ref81) / 100;
    },
    values: ["ref77", "ref81"]
  },
  ref91: {
    formula: (ref77, ref82) => {
      return (ref77 * ref82) / 100;
    },
    values: ["ref77", "ref82"]
  },
  ref92: {
    formula: (ref77, ref83) => {
      return (ref77 * ref83) / 100;
    },
    values: ["ref77", "ref83"]
  },
  ref93: {
    formula: (ref77, ref84) => {
      return (ref77 * ref84) / 100;
    },
    values: ["ref77", "ref84"]
  },
  ref94: {
    formula: (ref77, ref85) => {
      return (ref77 * ref85) / 100;
    },
    values: ["ref77", "ref85"]
  },
  ref95: {
    formula: (ref77, ref86) => {
      return (ref77 * ref86) / 100;
    },
    values: ["ref77", "ref86"]
  },
  ref103: {
    formula: (ref87, ref96) => {
      return ref87 / ref96 ?? 0;
    },
    values: ["ref87", "ref96"]
  },
  ref104: {
    formula: (ref88, ref97) => {
      return ref88 / ref97 ?? 0;
    },
    values: ["ref88", "ref97"]
  },
  ref105: {
    formula: (ref89, ref98, ref99) => {
      return ref89 / (ref98 + ref99) ?? 0;
    },
    values: ["ref89", "ref98", "ref99"]
  },
  ref106: {
    formula: (ref90, ref100) => {
      return ref90 / ref100 ?? 0;
    },
    values: ["ref90", "ref100"]
  },
  ref107: {
    formula: (ref91, ref101) => {
      return ref91 / ref101 ?? 0;
    },
    values: ["ref91", "ref101"]
  },
  ref108: {
    formula: (ref92, ref102) => {
      return ref92 / ref102 ?? 0;
    },
    values: ["ref92", "ref102"]
  },
  ref116: {
    formula: (ref96, ref97, ref98, ref99, ref100, ref101, ref102, ref109, ref110, ref111, ref112, ref113, ref114, ref115) => {
      return ref96 * ref109 + ref97 * ref110 + ref98 * ref111 + ref99 * ref112 + ref100 * ref113 + ref101 * ref114 + ref102 * ref115;
    },
    values: ["ref96", "ref97", "ref98", "ref99", "ref100", "ref101", "ref102", "ref109", "ref110", "ref111", "ref112", "ref113", "ref114", "ref115"]
  },
  ref117: {
    formula: (ref75, ref96, ref103, ref109) => {
      return (ref103 - ref109) * ref75 * ref96;
    },
    values: ["ref103", "ref109", "ref75", "ref96"]
  },
  ref118: {
    formula: (ref75, ref97, ref104, ref110) => {
      return (ref104 - ref110) * ref75 * ref97;
    },
    values: ["ref104", "ref110", "ref75", "ref97"]
  },
  ref119: {
    formula: (ref75, ref89, ref98, ref99, ref111, ref112) => {
      return (ref89 - ref98 * ref111 - ref99 * ref112) * ref75;
    },
    values: ["ref89", "ref98", "ref111", "ref99", "ref112", "ref75"]
  },
  ref120: {
    formula: (ref75, ref100, ref106, ref113) => {
      return (ref106 - ref113) * ref75 * ref100;
    },
    values: ["ref106", "ref113", "ref75", "ref100"]
  },
  ref121: {
    formula: (ref75, ref101, ref107, ref114) => {
      return (ref107 - ref114) * ref75 * ref101;
    },
    values: ["ref107", "ref114", "ref75", "ref101"]
  },
  ref122: {
    formula: (ref75, ref102, ref108, ref115) => {
      return (ref108 - ref115) * ref75 * ref102;
    },
    values: ["ref108", "ref115", "ref75", "ref102"]
  },
  ref123: {
    formula: (ref75, ref93) => ref93 * ref75,
    values: ["ref93", "ref75"]
  },
  ref124: {
    formula: (ref75, ref94) => ref94 * ref75,
    values: ["ref94", "ref75"]
  },
  ref125: {
    formula: (ref74, ref75, ref76) => (ref74 * ref76 * ref75) / 100,
    values: ["ref74", "ref76", "ref75"]
  },
  ref136: {
    formula: (ref126, ref127, ref128, ref129, ref130, ref131, ref132, ref133, ref134, ref135) =>
      100 * (1 - (ref126 + ref127 + ref128 + ref129 + ref130 + ref131 + ref132 + ref133 + ref134 + ref135) / 100),
    values: ["ref126", "ref127", "ref128", "ref129", "ref130", "ref131", "ref132", "ref133", "ref134", "ref135"]
  },
  ref137: {
    formula: (ref57, ref126) => (ref57 * ref126) / 100,
    values: ["ref57", "ref126"]
  },
  ref138: {
    formula: (ref57, ref127) => (ref57 * ref127) / 100,
    values: ["ref57", "ref127"]
  },
  ref139: {
    formula: (ref57, ref128) => (ref57 * ref128) / 100,
    values: ["ref57", "ref128"]
  },
  ref140: {
    formula: (ref57, ref129) => (ref57 * ref129) / 100,
    values: ["ref57", "ref129"]
  },
  ref141: {
    formula: (ref57, ref130) => (ref57 * ref130) / 100,
    values: ["ref57", "ref130"]
  },
  ref142: {
    formula: (ref57, ref131) => (ref57 * ref131) / 100,
    values: ["ref57", "ref131"]
  },
  ref143: {
    formula: (ref57, ref132) => (ref57 * ref132) / 100,
    values: ["ref57", "ref132"]
  },
  ref144: {
    formula: (ref57, ref133) => (ref57 * ref133) / 100,
    values: ["ref57", "ref133"]
  },
  ref145: {
    formula: (ref57, ref134) => (ref57 * ref134) / 100,
    values: ["ref57", "ref134"]
  },
  ref146: {
    formula: (ref57, ref135) => (ref57 * ref135) / 100,
    values: ["ref57", "ref135"]
  },
  ref147: {
    formula: (ref57, ref136) => (ref57 * ref136) / 100,
    values: ["ref57", "ref136"]
  },
  ref148: {
    formula: (ref96, ref139) => {
      return ref139 / ref96 ?? 0;
    },
    values: ["ref139", "ref96"]
  },
  ref149: {
    formula: (ref97, ref140) => {
      return ref140 / ref97 ?? 0;
    },
    values: ["ref140", "ref97"]
  },
  ref150: {
    formula: (ref98, ref99, ref141) => {
      return ref98 + ref99 ? ref141 / (ref98 + ref99) : 0;
    },
    values: ["ref98", "ref99", "ref141"]
  },
  ref151: {
    formula: (ref100, ref142) => {
      return ref142 / ref100 ?? 0;
    },
    values: ["ref142", "ref100"]
  },
  ref152: {
    formula: (ref101, ref143) => {
      return ref143 / ref101 ?? 0;
    },
    values: ["ref143", "ref101"]
  },
  ref153: {
    formula: (ref102, ref144) => {
      return ref144 / ref102 ?? 0;
    },
    values: ["ref144", "ref102"]
  },
  ref164: {
    formula: (ref61, ref161, ref162, ref163) => (ref161 - ref162) * (ref163 * 24) * ref61,
    values: ["ref161", "ref162", "ref163", "ref61"]
  },
  ref165: {
    formula: (ref166, ref167, ref168, ref169, ref170, ref171, ref172) => ref166 + ref167 + ref168 + ref169 + ref170 + ref171 + ref172,
    values: ["ref166", "ref167", "ref168", "ref169", "ref170", "ref171", "ref172"]
  },
  ref174: {
    formula: (ref166) => {
      return (0.0333 + 0.0044 + 0.0045 + 0.0089 * 1.14) * ref166;
    },
    values: ["ref166"]
  },
  ref175: {
    formula: (ref167, ref173) => {
      return (0.0029 * ref173 + 0.0286 + 0.0044 + 0.0045 + 0.0089 * 1.14) * ref167;
    },
    values: ["ref173", "ref167"]
  },
  ref176: {
    formula: (ref168) => {
      return (0.0076 + 0.0044 + 0.0045 + 0.0089 * 1.14) * ref168;
    },
    values: ["ref168"]
  },
  ref177: {
    formula: (ref169) => {
      return (0.0355 + 0.0044 + 0.0045 + 0.0089 * 1.14) * ref169;
    },
    values: ["ref169"]
  },
  ref178: {
    formula: (ref170) => {
      return (0.0242 + 0.0044 + 0.0045 + 0.0089 * 1.14) * ref170;
    },
    values: ["ref170"]
  },
  ref179: {
    formula: (ref171) => {
      return (0.0261 + 0.0044 + 0.0045 + 0.0089 * 1.14) * ref171;
    },
    values: ["ref171"]
  },
  ref180: {
    formula: (ref174, ref175, ref176, ref177, ref178, ref179) => {
      return ref174 + ref175 + ref176 + ref177 + ref178 + ref179;
    },
    values: ["ref174", "ref175", "ref176", "ref177", "ref178", "ref179"]
  },
  ref181: {
    formula: (ref61, ref96, ref148, ref154) => {
      return (ref148 - ref154) * ref61 * ref96;
    },
    values: ["ref148", "ref154", "ref61", "ref96"]
  },
  ref182: {
    formula: (ref61, ref97, ref149, ref155) => {
      return (ref149 - ref155) * ref61 * ref97;
    },
    values: ["ref149", "ref155", "ref61", "ref97"]
  },
  ref183: {
    formula: (ref61, ref98, ref99, ref141, ref156, ref157) => {
      return (ref141 - ref98 * ref156 - ref99 * ref157) * ref61;
    },
    values: ["ref141", "ref98", "ref156", "ref99", "ref157", "ref61"]
  },
  ref184: {
    formula: (ref61, ref100, ref151, ref158) => {
      return (ref151 - ref158) * ref61 * ref100;
    },
    values: ["ref151", "ref158", "ref61", "ref100"]
  },
  ref185: {
    formula: (ref61, ref101, ref152, ref159) => {
      return (ref152 - ref159) * ref61 * ref101;
    },
    values: ["ref152", "ref159", "ref61", "ref101"]
  },
  ref186: {
    formula: (ref61, ref102, ref153, ref160) => {
      return (ref153 - ref160) * ref61 * ref102;
    },
    values: ["ref153", "ref160", "ref61", "ref102"]
  },
  ref187: {
    formula: (ref61, ref145, ref180) => (ref145 - ref180) * ref61,
    values: ["ref145", "ref180", "ref61"]
  },
  ref188: {
    formula: (ref61, ref146) => ref146 * ref61,
    values: ["ref146", "ref61"]
  },
  ref189: {
    formula: (ref61, ref137) => ref137 * ref61,
    values: ["ref137", "ref61"]
  },
  ref190: {
    formula: (ref61, ref138) => ref138 * ref61,
    values: ["ref138", "ref61"]
  },
  ref191: {
    formula: () => 0,
    values: []
  }
};
const levelWiseValue = {
  level1: 0.9,
  level2: 0.92,
  level3: 0.95,
  level4: 0.99
};
