<template>
    <div class="search-con">
        <div class="styled-input wide">
            <b-form-input list="input-list" id="input-with-list" class="form-control select-text select-text-pd input-f"
                ref="ownerIF" v-model="selectedUser" :placeholder="isDisabled ? '' : 'Type Here'" :disabled="isDisabled"
                @input="findUser" @focus="focusIn" @blur="focusOut"></b-form-input>
            <!-- <input class="form-control select-text select-text-pd input-f" min="0"
                                        placeholder="Type Here" @input="changingF(index)" v-model="parameter.individualName"
                                        :disabled="parameter.dataTypeId !== 1" /> -->
            <input type="hidden" :value="selecteduserA" />

        </div>
        <div class="sh-user-con" v-if="serachResult.length > 0">
            <div class="sh-user-list" v-for="user in  serachResult" :key="user.id" @click="userSelected(user)">
                {{ user.userName }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SearchIndividual",
    props: ["users", "editUser", 'isDisabled'],
    data() {
        return {
            showLoader: false,
            focused: false,
            selectedUser: "",
            serachResult: [],
            selectedUSerId: "",
            flag: false,
        };
    },

    created() {
        this.selectedUser = this.editUser;
    },
    computed: {
        selecteduserA() {
            if (!this.selectedUser && !this.focused)
                this.selectedUser = this.editUser
            return this.selectedUser;
        },
    },
    methods: {
        focusIn() {
            this.focused = true
        },
        focusOut() {
            this.focused = false
        },
        findUser() {
            this.serachResult = this.users.filter((user) => {
                return (this.selectedUser.toLowerCase() && user.userName.toLowerCase().includes(this.selectedUser.toLowerCase()));
            });
        },
        userSelected(selectedUserObj) {
            console.log(selectedUserObj)
            this.selectedUser = selectedUserObj.userName;
            this.selectedUSerId = selectedUserObj.userId;
            this.serachResult = [];
            this.$emit("userSelected", selectedUserObj);
        },
    },
};
</script>
  
<style scoped>
.search-box {
    height: 100%;
    border: 1px solid #313131;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.search-box:focus {
    border: 2px solid #274ba7;
}

.search-box:focus+.user-lable {
    display: block;
}

.recent-users {
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

.user-lable {
    font-size: 1rem;
    font-family: "BarlowM", sans-serif;
}

.down-icon {
    font-size: 1rem;
    position: absolute;
    right: 3%;
    top: 1.9rem;
    color: #274ba7;
}

.search-con {
    position: relative;
}

.sh-user-con {
    background: #fff;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    border-radius: 7px;
    position: absolute;
    z-index: 3;
}

.sh-user-list {
    padding: 1rem;
    border-bottom: 1px solid #f5efef;
    font-size: 1.166rem;
    font-family: "BarlowM", sans-serif;
    cursor: pointer;
}

#input-with-list {
    padding: 1rem;
}

.comment-lable {
    color: #274ba7;
    top: -0.8rem !important;
    left: 1rem !important;
    background: #fff;
    padding: 0 4px !important;
}

.styled-input {
    width: 33.3333%;
    position: relative;
}

.styled-input label {
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;
}

.styled-input.wide {
    width: 100%;
    height: 4.3rem;
}

.review-box {
    padding: 1rem 1rem;
    width: 100%;
    font-size: 1.166rem;
    font-family: "BarlowM", sans-serif;
    border: 1px solid #313131;
    border-radius: 6px;
    height: 100%;
    color: #313131
}
</style>