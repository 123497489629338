<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con" style="padding: 0">
            <div class="even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex; margin: 2rem; justify-content: space-between">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text">Data Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                            v-model="committedYr" @change="getPlantDetails(); getParameterDetails()">
                            <option v-for="year in committedYearList" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            v-model="selectedPlantId" @change="plantChanged($event)">
                            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                {{ plant.energyPlantName }},
                                {{ plant.regionName }}
                            </option>
                        </select>
                        <span style="color: red" v-if="showPlantConfigureMsg"> Please configure the Plant & Region in user
                            profile. </span>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="period" style="background-color: #f5f5f5" class="form-label select-lable lable-text">
                            Period </label>
                        <select class="form-control select-text select-text-pd cust-st-icon"
                            style="background-color: #f5f5f5" id="period" v-model="selectedPeriod"
                            @change="getParameterDetails()" disabled>
                            <option v-for="wc in periodList" v-bind:key="wc.periodId" :value="wc.periodId">
                                {{ wc.periodName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="utility" class="form-label select-lable lable-text"> Utility </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="utility"
                            v-model="selectedUtility" @change="onChangeUtility()">
                            <option v-for="plr in utilityList" v-bind:key="plr.utilityId" :value="plr.utilityId">
                                {{ plr.utilityName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="title-with-Plant default-font-color" v-if="titleWithPlant">
            {{ titleWithPlant }}

            <div class="button-dd">
                <label class="btn ed-st-btn"
                    style="margin: 0 1rem; padding: 6px 1.3rem; border: transparent; background: transparent; color: #274ba7"
                    pill variant="outline-primary">
                    <span style="font-size: large; font-weight: bold"> Status : {{ statusMsg }} </span>
                </label>
                <b-button v-if="showPlantBtn && $oidc.userProfile.roles.includes(this.roles.POENERGYADMIN)" pill
                    variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem; margin-right: 20px"
                    class="btn cancel-save-btn button-lg" @click="resetStatustoEdit()">
                    <span style="font-weight: bolder">Send Back to Plant</span>
                </b-button>
                <div title="Export to Excel" class="download-view action-btn" @click="download" style="margin-top: 3px">
                    <img alt="download" src="@/assets/download_icon.svg" />
                </div>
            </div>
        </div>
        <div class="main-con">
            <div class="outer-block">
                <div class="tab-titles">
                    <div class="tab-options">
                        <div class="d-flex gap-closure-header-sub-con" v-for="tabInfo in tabList"
                            :key="tabInfo.consumptionId">
                            <div class="in-action">
                                <div class="primary-color tab-option-text"
                                    :class="{ 'tab-active-option': activeTab === tabInfo.consumptionName.toLowerCase() }"
                                    @click="tabSwitch(tabInfo.consumptionId)">
                                    {{ tabInfo.consumptionName }}
                                </div>
                                <hr class="active-tab-scroll-hr"
                                    v-if="activeTab === tabInfo.consumptionName.toLowerCase()" />
                            </div>
                        </div>
                    </div>
                    <div class="btn-container zero-padding-margin">
                        <b-button class="btn ed-st-btn"
                            style="margin: 0 1rem; padding: 0 1.3rem; border: transparent; background: transparent; color: #274ba7; font-weight: bold"
                            pill variant="outline-primary" @click="hideAll()">
                            Hide All Details
                        </b-button>
                        <b-button class="btn ed-st-btn"
                            style="margin: 0 1rem; padding: 0 1.3rem; border: transparent; background: transparent; color: #274ba7; font-weight: bold"
                            pill variant="outline-primary" @click="viewAll()">
                            View All Details
                        </b-button>
                    </div>
                </div>
                <hr class="hr" />
                <div class="card-container" v-for="(parameter, index) in parameterList" :key="parameter.refId">
                    <div class="col-12-md card-sub-container">
                        <div class="col-12-md parameter-name">
                            {{ parameter.parameterName }}
                        </div>
                        <div class="even-space-vertical-align" style="flex-direction: column">
                            <div v-if="editMode" class="input-field-container custom-padding">
                                <div class="input-field-div" style="justify-content: space-between">
                                    <div>
                                        <label for="committed_year"> Value </label>
                                        <input class="form-control select-text select-text-pd input-f" min="0"
                                            placeholder="Type Here" @input="changingValue(index, parameter, $event)"
                                            v-model="parameter.value" pattern="^[0-9]"
                                            @click="changeBackToNumber(parameter, $event)"
                                            @blur="formatInputValues(parameter, $event)"
                                            :disabled="parameter.dataTypeId !== 1" />
                                        <!-- @blur="formatInputValues(parameter, $event)" -->

                                        <label class="prev-values"
                                            v-if="parameter.previousValue || parameter.previousValue === 0">
                                            {{ `${parameter.prevPeriodYear} ${parameter.previousPeriodName} value :` }}
                                            {{ `${formatNumberToenUS(parameter.previousValue)}` }}
                                        </label>
                                        <label class="values-correct" v-if="isValueNumber(parameter.value)">
                                            {{ isValueNumber(parameter.value) }}
                                        </label>
                                    </div>
                                    <div class="input-field-container" style="justify-content: flex-start; padding: 0rem 0">
                                        <b-button class="btn ed-st-btn" v-if="showDetails(index)"
                                            style="border: transparent; background: transparent; color: #274ba7" pill
                                            variant="outline-primary" @click="viewDetail(parameter, index)">
                                            View Details <img alt="view" src="@/assets/Arrow_Dropdown.svg" />
                                        </b-button>
                                        <b-button class="btn ed-st-btn" v-if="!showDetails(index)"
                                            style="border: transparent; background: transparent; color: #274ba7" pill
                                            variant="outline-primary" @click="hideDetail(parameter, index)">
                                            Hide Details <img alt="hide" id="hide" src="@/assets/Arrow_Dropdown.svg" />
                                        </b-button>
                                    </div>
                                </div>
                                <div class="input-field-div">
                                    <label for="plant"> Responsible Individual </label>
                                    <input class="form-control select-text select-text-pd input-f" min="0"
                                        v-model="parameter.individualName" :disabled="parameter.dataTypeId !== 1"
                                        :placeholder="parameter.dataTypeId !== 1 ? '' : 'Type Here'" />

                                    <!-- <SearchIndividual :users="ownerList" :editUser="existingUser(parameter.individualName)"
                                        :isDisabled="parameter.dataTypeId !== 1"
                                        @userSelected="userSelected($event, index)"></SearchIndividual> -->
                                </div>
                                <div class="input-field-div">
                                    <label for="period"> Data Source </label>
                                    <input class="form-control select-text select-text-pd input-f" min="0"
                                        :placeholder="parameter.dataTypeId !== 1 ? '' : 'Type Here'"
                                        v-model="parameter.dataSource" :disabled="parameter.dataTypeId !== 1" />
                                </div>
                                <div class="input-field-div">
                                    <label for="commentSection"> Comments </label>
                                    <textarea class="page-content-regular action-desc" id="commentSection"
                                        name="commentSection" v-model="parameter.comments" rows="4" cols="80"
                                        :disabled="parameter.dataTypeId !== 1" ref="actionDetailTA"
                                        :placeholder="parameter.dataTypeId !== 1 ? '' : 'Type Here'"
                                        maxlength="100"></textarea>
                                </div>
                            </div>
                            <div v-else class="input-field-container custom-padding">
                                <div class="input-field-div" style="justify-content: space-between">
                                    <div>
                                        <label for="committed_year"> Value </label>
                                        <input class="form-control select-text select-text-pd input-f" min="0"
                                            placeholder="" @input="changingValue(index, parameter, $event)"
                                            v-model="parameter.value" pattern="^[0-9]"
                                            @click="changeBackToNumber(parameter, $event)"
                                            @blur="formatInputValues(parameter, $event)" disabled />
                                        <label class="prev-values"
                                            v-if="parameter.previousValue || parameter.previousValue === 0">
                                            {{ `${parameter.prevPeriodYear} ${parameter.previousPeriodName} value :` }}
                                            {{ `${formatNumberToenUS(parameter.previousValue)}` }}
                                        </label>
                                    </div>
                                    <div class="input-field-container" style="justify-content: flex-start; padding: 0rem 0">
                                        <b-button class="btn ed-st-btn" v-if="showDetails(index)"
                                            style="border: transparent; background: transparent; color: #274ba7" pill
                                            variant="outline-primary" @click="viewDetail(parameter, index)">
                                            View Details <img alt="view" src="@/assets/Arrow_Dropdown.svg" />
                                        </b-button>
                                        <b-button class="btn ed-st-btn" v-if="!showDetails(index)"
                                            style="border: transparent; background: transparent; color: #274ba7" pill
                                            variant="outline-primary" @click="hideDetail(parameter, index)">
                                            Hide Details <img alt="hide" id="hide" src="@/assets/Arrow_Dropdown.svg" />
                                        </b-button>
                                    </div>
                                </div>
                                <div class="input-field-div">
                                    <label for="plant"> Responsible Individual </label>
                                    <input class="form-control select-text select-text-pd input-f" min="0" placeholder=""
                                        v-model="parameter.individualName" disabled />

                                    <!-- <SearchIndividual :users="ownerList" :editUser="existingUser(parameter.individualName)"
                                        :isDisabled="true" @userSelected="userSelected($event, index)"></SearchIndividual> -->
                                </div>
                                <div class="input-field-div">
                                    <label for="period"> Data Source </label>
                                    <input class="form-control select-text select-text-pd input-f" min="0" placeholder=""
                                        v-model="parameter.dataSource" disabled />
                                </div>
                                <div class="input-field-div">
                                    <label for="commentSection"> Comments </label>
                                    <textarea class="page-content-regular action-desc" id="commentSection"
                                        name="commentSection" v-model="parameter.comments" rows="4" cols="80" disabled
                                        ref="actionDetailTA" placeholder="" maxlength="100"></textarea>
                                </div>
                            </div>

                            <!-- <div class="input-field-container" style="justify-content: flex-end; padding: 0.6rem 0">
                                <b-button class="btn ed-st-btn" v-if="showDetails(index)"
                                    style="border: transparent; background: transparent; color: #274ba7" pill
                                    variant="outline-primary" @click="viewDetail(parameter, index)">
                                    View Details <img alt="view" src="@/assets/Arrow_Dropdown.svg" />
                                </b-button>
                                <b-button class="btn ed-st-btn" v-if="!showDetails(index)"
                                    style="border: transparent; background: transparent; color: #274ba7" pill
                                    variant="outline-primary" @click="hideDetail(parameter, index)">
                                    Hide Details <img alt="hide" id="hide" src="@/assets/Arrow_Dropdown.svg" />
                                </b-button>
                                
                            </div> -->
                        </div>
                    </div>
                    <div class="col-12-md card-sub-container" style="padding: 0.7rem" v-if="!showDetails(index)">
                        <div class="input-field-container" style="justify-content: flex-start">
                            <div class="sub-card">
                                <label>REF</label>
                                <span>{{ parameter.refId }}</span>
                            </div>
                            <div class="sub-card">
                                <label>Utility</label>
                                <span>{{ parameter.utility }}</span>
                            </div>
                            <div class="sub-card">
                                <label>Consumption</label>
                                <span>{{ parameter.consumption }}</span>
                            </div>
                        </div>
                        <div class="input-field-container" style="justify-content: flex-start">
                            <div class="sub-card">
                                <label>Description</label>
                                <span>{{ parameter.parameterDefination }}</span>
                            </div>
                        </div>
                        <div class="input-field-container" style="justify-content: flex-start">
                            <div class="sub-card">
                                <label>Notes/Examples</label>
                                <span>{{ parameter.parameterExample }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-container" v-if="!parameterList.length"></div>
                <div class="btn-container">
                    <b-button class="btn ed-st-btn" style="border: transparent; background: transparent; color: #274ba7"
                        pill variant="outline-primary" @click="$router.go(-1)">
                        Cancel
                    </b-button>
                    <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem; margin-left: 1rem" pill
                        v-if="editMode" variant="outline-primary" @click="saveDraft()">
                        Save Draft
                    </b-button>
                    <!-- <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                        v-if="showSubmitBtn" class="btn save-btn button-sm" @click="submit()">
                        Submit
                    </b-button> -->
                </div>
            </div>
        </div>

        <!-- Data is drafted successfully. -->
        <b-modal id="draftAlert" :data-backdrop="true" :no-close-on-backdrop="true" :no-close-on-esc="true"
            class="modal-body" ref="draftAlertMsg" hide-footer hide-header-close hide-header :visible="showDraftPopup">
            <div class="col-12 no-padding page-content-regular">
                <p class="page-heading-tab" style="text-align: justify">
                    {{ draftMsg }}
                </p>
            </div>
            <div class="button-div">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="submitData()">
                    Yes
                </b-button>
                <b-button pill variant="outline-secondary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="onClickNo()">
                    No
                </b-button>
            </div>
        </b-modal>

        <!-- Successfully submitted the data. -->
        <b-modal id="sucsAlert" :data-backdrop="true" :no-close-on-backdrop="true" :no-close-on-esc="true" size="sm"
            ref="sucsAlertMsg" hide-footer hide-header-close hide-header :visible="showSucsPopup">
            <div class="col-12 no-padding page-content-regular">
                <p class="page-heading-tab" style="text-align: justify">
                    {{ sucstMsg }}
                </p>
            </div>
            <div class="button-div">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="showSuccessPopup()">
                    OK
                </b-button>
            </div>
        </b-modal>

        <!-- Missing Parameters -->
        <b-modal id="pillarAlert" :data-backdrop="true" :no-close-on-backdrop="true" :no-close-on-esc="true" size="sm"
            ref="pillarMsg" hide-footer hide-header-close :title="headTextForpar" :visible="showPopup">
            <div class="col-12 no-padding page-content-regular">
                <p class="page-heading-tab" style="text-align: justify">
                    {{ errorMsg }}
                </p>
            </div>
            <div class="button-div">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="showPopup = false">
                    OK
                </b-button>
            </div>
        </b-modal>

        <!-- Required Validations-->

        <b-modal id="reqrdChecks" :data-backdrop="true" :no-close-on-backdrop="true" :no-close-on-esc="true"
            class="modal-body" ref="reqrdChecksMsg" hide-footer hide-header-close :title="headTextForReqChecks"
            :visible="reqrdChecksPopUp">
            <p class="page-heading-tab" style="text-align: justify">
                <span style="white-space: pre-line">{{ reqrdChecksMsg }}</span>
            </p>
            <div class="button-div">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="reqrdChecksPopUp = false">
                    Ok
                </b-button>
            </div>
        </b-modal>

        <!-- Save Checks Validation popup -->
        <b-modal id="checksAlert" :data-backdrop="true" :no-close-on-backdrop="true" :no-close-on-esc="true" size="lg"
            class="modal-body" ref="checksAlertMsg" hide-footer hide-header-close :title="headTextForchecks"
            :visible="checksPopUp">
            <div class="col-12 no-padding page-content-regular">
                <!-- Checks Tables -->

                <div style="display: flex; flex-wrap: wrap">
                    <strong>Checks - Total Electric Use Reported</strong>
                    <div class="machine-action-tracker">
                        <b-table-simple responsive outlined id="machine-action-tracker" sticky-header
                            class="machine-action-table scroll gn-table-b">
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th> REF ID </b-th>
                                    <b-th> Area </b-th>
                                    <b-th> KWH Use</b-th>
                                </b-tr>
                            </b-thead>

                            <b-tbody>
                                <b-tr v-for="(rowData, index) in checkListForElectricUse" :key="index"
                                    :class="{ 'add-top-border': rowData.addBorder }">
                                    <b-td>{{ rowData.refId }}</b-td>
                                    <b-td>{{ rowData.area }}</b-td>
                                    <b-td> {{ rowData.kwhUse }} </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </div>

                <div style="display: flex; flex-wrap: wrap">
                    <div class="machine-action-tracker">
                        <strong>Checks - Other REF IDs</strong>
                        <b-table-simple responsive outlined id="machine-action-tracker" sticky-header
                            class="machine-action-table scroll gn-table-b">
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th> REF ID </b-th>
                                    <b-th> Area </b-th>
                                    <b-th> KWH Use</b-th>
                                    <b-th> % of Total</b-th>
                                </b-tr>
                            </b-thead>

                            <b-tbody>
                                <b-tr v-for="(rowData, index) in checkList" :key="index"
                                    :class="{ 'add-top-border': rowData.addBorder }">
                                    <b-td>{{ rowData.refId }}</b-td>
                                    <b-td>{{ rowData.area }}</b-td>
                                    <b-td> {{ rowData.kwhUse }} </b-td>
                                    <b-td>{{ rowData.totalPercent }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </div>

                <!--- END-- Checks Tables -->
                <h5 style="background-color: rgba(255, 174, 66)">
                    Are the KWH and % of total electricity left over for Lighting, Component Preparation, and Other
                    reasonable? This takes into consideration all other electricity KWH that
                    have already been allocated in the model.
                </h5>
            </div>
            <div class="button-div">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="proceedtoSubmit()">
                    Proceed to Submit
                </b-button>
                <b-button pill variant="outline-secondary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="ClickNo()">
                    No
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import "@/common/css/CommonStyle.css";
import "@/common/css/font-categories.css";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Loader from "@/components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "@/common/constants/api/index";
import MessagePopup from "@/components/common/MessagePopup.vue";
import moment from "moment";
import { MonthPickerInput } from "vue-month-picker";
import { formulas } from "@/views/EnergyLoss/CalulationFormula";
import { mapGetters } from "vuex";
import SearchIndividual from "./SearchIndividual.vue";
import { export_json_to_excel } from "@/common/helper";
import Roles from "@/common/constants/roles";

export default {
    name: "EnergyLossCalculator",
    data() {
        return {
            checkNewData: true,
            showLoader: true,
            editMode: false,
            showPlantBtn: false,
            showPopup: false,
            showSucsPopup: false,
            showDraftPopup: false,
            sucstMsg: "",
            headTextForpar: "Please provide the missing REF values..!",
            errorMsg: "",
            draftMsg: "",
            statusMsg: "New",
            activeTab: "distribute",
            selectedConsumption: 5,
            tabList: [],
            committedYr: new Date().getFullYear(),
            committedYearList: [2023, 2022],
            plantId: {},
            plantList: [],
            selectedPlantId: 1,
            resetStatus: false,
            layoutId: 1,
            selectedPeriod: 8,
            periodList: [],
            selectedUtility: 3,
            utilityList: [],
            parameterList: [],
            rawParameterList: [],
            templateXls: [
                ["REF"],
                ["Utility"],
                ["Consumption"],
                ["Data Type"],
                ["Description"],
                ["Value"],
                ["Definition"],
                ["Notes / Examples"],
                ["Responsible Individual"],
                ["Data Source"],
                ["Comments"]
            ],
            templateXlsData: [],
            refIdByValue: {},
            ownerList: [],
            oldUtilityVal: "",
            newUtilityVal: "",
            checksPopUp: false,
            checksAlertMsg: "",
            headTextForchecks: "Please review the below CHECKS.",
            headTextForReqChecks: "Please review and correct the below CHECKS.",
            checkListForElectricUse: [],
            checkList: [],
            reqrdChecksPopUp: false,
            reqrdChecksMsg: "",
            showPlantConfigureMsg: false,
            roles: Roles
        };
    },
    components: {
        Breadcrumb,
        Loader,
        MessagePopup,
        MonthPickerInput,
        SearchIndividual
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 5,
            subMenu: 1
        });
        this.$store.dispatch("breadcrumb", {
            title: "Energy Zero Loss Calculator",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Energy Zero Loss Calculator", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.getConsumption();
        this.getCommittedYear();
        this.getPlantDetails();
        this.getOwnerList();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "prevSelectedWorkCenter", "loggedInUserData", "pillarPrevSelected"]),
        titleWithPlant() {
            const plantName = this.plantList.find((plant) => {
                return plant.plantId === Number(this.selectedPlantId);
            });
            return this.plantId.hasOwnProperty("energyPlantName") && plantName ? `Energy Zero Loss For ${plantName.energyPlantName ?? " "}, ${plantName.regionName ?? " "}` : " ";
        }
    },
    methods: {

        isValueNumber(value) {
            if (!isNaN(value)) return "";
            if (!isNaN(value.replaceAll(",", ""))) return "";
            else return "Please enter numeric values only";
        },
        estTimeConvert: function (hstDt) {
            let offset = -5.0;
            let dt = new Date(hstDt);
            let neDt = dt.valueOf();
            let serverDate = new Date(neDt + 3600000 * offset);
            let estDtTime = serverDate.toLocaleString("en-US");
            let newdt = moment(new Date(estDtTime)).format("DD-MMM-YYYY hh:mm:ss A");
            return newdt;
        },
        tabSwitch(pos) {
            this.selectedConsumption = pos;
            switch (pos) {
                case 1:
                    this.activeTab = "distribute";
                    this.showLoader = true;
                    break;
                case 2:
                    this.activeTab = "generate";
                    this.showLoader = true;
                    break;

                case 3:
                    this.activeTab = "purchase";
                    this.showLoader = true;
                    break;

                case 4:
                    this.activeTab = "use";
                    this.showLoader = true;
                    break;

                case 5:
                    this.activeTab = "all";
                    this.showLoader = true;
                    break;

                default:
                    break;
            }
            this.parameterList = [];
            this.parameterList1 = [];
            if (this.selectedConsumption != 5) {
                this.parameterList = this.rawParameterList.filter((parameter) => parameter.utilityId === this.selectedUtility && parameter.consumptionId === this.selectedConsumption);
            } else {
                this.parameterList = this.rawParameterList.filter((parameter) => parameter.utilityId === this.selectedUtility);
                this.parameterList.sort((a, b) => {
                    if (Number(a.refId) < Number(b.refId)) {
                        return -1;
                    }
                    if (Number(a.refId) > Number(b.refId)) {
                        return 1;
                    }
                    return 0;
                });
                console.log("parameterList", this.parameterList);
            }
            console.log("parameterList", this.parameterList);
            setTimeout(() => {
                this.showLoader = false;
            }, 200);
        },
        download() {
            const templateXlsData = this.rawParameterList.map((para) => {
                const values = para.rawValue ?? para.value;
                return [
                    para.refId,
                    para.utility,
                    para.consumption,
                    para.dataTypeName,
                    para.parameterName,
                    values,
                    para.parameterDefination,
                    para.parameterExample,
                    para.individualName,
                    para.dataSource,
                    para.comments
                ];
            });

            let selectedPeriod = this.periodList.find((p) => p.periodId === this.selectedPeriod);
            this.showLoader = true;
            setTimeout(() => {
                this.showLoader = false;
            }, 5000);
            export_json_to_excel({
                multiHeader: [],
                header: this.templateXls,
                data: templateXlsData,
                filename: `Energy_Saving_Calculator_${this.plantId.energyPlantName ?? " "}_${this.committedYr}_${selectedPeriod.periodName}`,
                merges: [],
                autoWidth: false,
                bookType: "xlsx"
            });
        },
        isNumber(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isDecimal(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;

            if (evt.ctrlKey && (charCode === 65 || charCode === 67 || charCode === 86)) return true;
            if (charCode === 190 || charCode === 110) {
                return true;
            }

            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isValidFormat(e) {
            this.isDecimal(e);
        },
        changingValue(index, parameter, event) {
            let pos = this.rawParameterList.findIndex((el) => el.parameterId === parameter.parameterId);
            if (pos !== -1) {
                let newVal = 0;
                if (!isNaN(event.target.value)) newVal = event.target.value;
                else if (!isNaN(event.target.value.replaceAll(",", ""))) newVal = event.target.value.replaceAll(",", "");
                this.rawParameterList[pos]["value"] = pos !== -1 ? newVal : null;
                this.rawParameterList[pos]["rawValue"] = pos !== -1 ? newVal : null;
            }

            Object.keys(formulas).forEach((refId) => {
                let tempRefid = refId;
                if (this.layoutId === 2 && refId === "ref63") {
                    tempRefid = "ref63cg";
                }
                if (this.layoutId === 2 && refId === "ref9") {
                    tempRefid = "ref9cg";
                }
                const obj = formulas[tempRefid];
                if (obj.hasOwnProperty("formula")) {
                    let val = this.rawParameterList.map((para) => {
                        let reqValue = para.hasOwnProperty("rawValue") ? para.rawValue : para.value;
                        reqValue = obj.values.includes(para.ref2Id.toLowerCase()) ? Number(reqValue) : null;
                        return reqValue;
                    });

                    val = val.filter((v) => !!v || v == 0);
                    if (val.length) {
                        let pos = this.rawParameterList.findIndex((p) => {
                            return p.ref2Id.toLowerCase() === refId;
                        });
                        const output = Number(obj.formula(...val));
                        if (pos !== -1) {
                            if (output === Infinity || output === -Infinity) console.log("o/p is infinity ", tempRefid, output);
                            else {
                                this.rawParameterList[pos].value = output ? this.formatNumberToenUS(this.formatValue({ value: output, dataTypeId: 0 })) : 0;
                                this.rawParameterList[pos]["rawValue"] = output ? output : 0;
                            }
                        }
                    }
                } else console.log("*", obj);
            });
        },
        formatValue({ value, dataTypeId = 0 }) {
            if (dataTypeId === 1) return value;
            else return Math.round(value * 100) / 100;
        },
        formatInputValues(parameter, event) {
            let pos = this.rawParameterList.findIndex((el) => el.parameterId === parameter.parameterId);

            if (pos !== -1) {
                let newVal = 0;
                if (!isNaN(event.target.value)) newVal = event.target.value;
                else if (!isNaN(event.target.value.replaceAll(",", ""))) newVal = event.target.value.replaceAll(",", "");
                this.rawParameterList[pos]["value"] = pos !== -1 ? this.formatNumberToenUS(newVal) : null;
                this.rawParameterList[pos]["rawValue"] = pos !== -1 ? newVal : null;
            }
        },
        changeBackToNumber(parameter, event) {
            let pos = this.rawParameterList.findIndex((el) => el.parameterId === parameter.parameterId);

            if (pos !== -1) {
                this.rawParameterList[pos]["value"] = pos !== -1 ? event.target.value.replaceAll(",", "") : null;

            }
        },
        showDetails(index) {
            return this.parameterList[index].showDetail;
        },
        hideAll() {
            this.parameterList.forEach((parameter) => {
                parameter["showDetail"] = true;
            });
            this.showLoader = true;

            setTimeout(() => {
                this.showLoader = false;
            }, 1000);
        },
        viewAll() {
            this.parameterList.forEach((parameter) => {
                parameter["showDetail"] = false;
            });

            this.showLoader = true;
            setTimeout(() => {
                this.showLoader = false;
            }, 1000);
        },
        hideDetail(_obj, pos) {
            this.parameterList[pos].showDetail = true;
            this.showLoader = true;
            setTimeout(() => {
                this.showLoader = false;
            }, 1000);
        },
        viewDetail(_obj, pos) {
            this.parameterList[pos].showDetail = false;
            this.showLoader = true;
            setTimeout(() => {
                this.showLoader = false;
            }, 1000);
        },
        existingUser(individualEmail) {
            const obj = this.ownerList.find((owner) => {
                return owner.email === individualEmail ? owner : null;
            });
            return obj && obj.userName ? obj.userName : "";
        },
        userSelected(evt, index) {
            this.parameterList[index].individualName = evt.email;
        },
        getOwnerList() {
            ApiCalls.httpCallDetails(Api.GETLISTOFUSERS, "get").then((dataJSON) => {
                if (dataJSON.success) {
                    dataJSON.data.sort((a, b) => {
                        if (a.userName < b.userName) return -1;
                        else return a.userName === b.userName ? 0 : 1;
                    });
                    this.ownerList = JSON.parse(JSON.stringify(dataJSON.data));
                }
                this.showLoader = false;
            });
        },
        resetStatustoEdit() {
            let newPlantId = this.$route.query.stPlantId;
            let newYear = this.$route.query.styear;
            this.showLoader = true;
            let resetPayload = {
                year: newYear === undefined ? this.committedYr : newYear,
                plantId: newPlantId === undefined ? this.plantId.plantId : Number(newPlantId)

            };
            ApiCalls.httpCallDetails(Api.GETRESETSTATUS, "post", resetPayload).then((data) => {
                console.log("Data", data);
                if (data.data.isDraft === true) {
                    this.resetStatus = true;
                    this.checkStatus();
                }
                this.showLoader = false;
            });
        },
        checkStatus() {
            let newPlantId = this.$route.query.stPlantId;
            let newYear = this.$route.query.styear;
            let stPayload = {
                year: newYear === undefined ? this.committedYr : Number(newYear),
                periodId: 0,
                plantId: newPlantId === undefined ? this.plantId.plantId : Number(newPlantId)
            };

            console.log("stPayload", stPayload);
            ApiCalls.httpCallDetails(Api.ENGERYLOSSSTATUS, "post", stPayload).then((data) => {
                console.log("data for Status", data);
                if (!data.data) this.editMode = true;
                else if (data.data.hasOwnProperty("isDraft")) this.editMode = data.data.isDraft;

                if (data.data != null) {
                    if (data.data.isSubmitted === true) {
                        this.statusMsg = "SUBMITTED";
                        this.showPlantBtn = true;
                    } else if (data.data.isDraft === true) {
                        this.statusMsg = "DRAFT";
                        this.showPlantBtn = false;
                    }
                } else {
                    this.statusMsg = "NEW";
                    this.showPlantBtn = false;
                }
            });
        },
        getConsumption() {
            let newPlantId = this.$route.query.stPlantId;
            let newYear = this.$route.query.styear;
            ApiCalls.httpCallDetails(Api.GETCONSUMPTION, "get").then((data) => {
                data.data.sort((a, b) => {
                    if (a.consumptionName > b.consumptionName) return 1;
                    if (a.consumptionName < b.consumptionName) return -1;
                    else return 0;
                });
                this.tabList = data.data;
                console.log("this.tabList", this.tabList);
            });
            if (newPlantId) {
                this.committedYr = newYear;
            }
        },
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.GETENERGYCOMMITTEDYEAR, "get").then((data) => {
                console.log(data);
                this.committedYearList = data.data;
                this.committedYr = data.data[0];
            });
        },
        getPlantLayout() {
            ApiCalls.httpCallDetails(Api.GETPLANTLAYOUT + this.plantId.plantId, "get").then((data) => {
                console.log(data);
                this.layoutId = data.data.layoutId;
            });
        },
        getPlantDetails() {
            let newPlantId = this.$route.query.stPlantId;
            let newyear = this.$route.query.styear;
            console.log("newyear", newyear);

            this.showLoader = true;
            ApiCalls.httpCallDetails(Api.GETENERGYLOSSREGIONS, "get").then((data) => {
                console.log("PlantDetails", data.data.length);
                let dataLength = data.data.length;
                if (dataLength !== 0) {
                    const regionList = data.data;
                    let regList = regionList.map((region) => region.energyRegionId);
                    let plntPayload = {
                        year: this.committedYr,
                        regionIds: regList
                    };
                    console.log("plntPayload", plntPayload);
                    ApiCalls.httpCallDetails(Api.GETENERGYLOSSPLANTS, "post", plntPayload).then((data) => {
                        console.log(data);
                        data.data.forEach((plant) => {
                            plant["regionName"] = regionList.find((region) => region.energyRegionId === plant.energyRegionId).energyRegionName;
                            plant["plantId"] = plant.energyPlantId;
                        });
                        data.data.sort((a, b) => {
                            if (a.regionName > b.regionName) return 1;
                            if (a.regionName < b.regionName) return -1;
                            else return 0;
                        });
                        if (newPlantId !== undefined) {
                            this.selectedPlantId = newPlantId;
                        } else {
                            this.selectedPlantId = data.data[0].energyPlantId;
                        }
                        this.plantList = data.data;
                        this.plantId = data.data[0];
                        console.log("this.plantId", this.plantId);
                        this.getPlantLayout();
                        this.showLoader = false;
                        this.getPeriods();
                        this.checkStatus();
                        console.log("data.data[0]", data.data[0]);
                    });
                } else {
                    this.showPlantConfigureMsg = true;
                }
            });
        },
        plantChanged(event) {
            this.$router.push({ path: "/energy-loss-calculator" });
            this.showLoader = true;
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            console.log("this.plantId", this.plantId);
            this.getParameterDetails();
            this.getPlantLayout();
            this.getPeriods();
            this.checkStatus();
        },
        getPeriods() {
            ApiCalls.httpCallDetails(Api.GETPERIOD, "get").then((data) => {
                this.periodList = data.data.filter((x) => x.periodId == 8);
                this.getUtility();
                this.selectedPeriod = 8;
            });
        },
        getUtility() {
            ApiCalls.httpCallDetails(Api.GETUTILITY, "get").then((data) => {
                console.log(data.data.sort((a, b) => -1));
                this.utilityList = data.data;
                this.getParameterDetails();
            });
        },
        onChangeUtility() {
            console.log("this.selectedConsumption", this.selectedConsumption);
            this.selectedConsumption = 5;
            this.tabSwitch(this.selectedConsumption);
        },
        getParameterDetails() {
            let newPlantId = this.$route.query.stPlantId;
            let newYear = this.$route.query.styear;
            this.selectedConsumption = 5;
            this.showLoader = true;
            const consumptionIds = this.tabList.map((tab) => tab.consumptionId);
            const utilityIds = this.utilityList.map((tab) => tab.utilityId);
            let payload = "";
            payload = {
                utilityId: utilityIds,
                consumptionId: consumptionIds,
                layoutId: this.layoutId,
                year: newYear === undefined ? this.committedYr : Number(newYear),
                plantId: newPlantId === undefined ? this.plantId.plantId : Number(newPlantId)
            };
            console.log("payload", payload);
            this.parameterList = [];
            ApiCalls.httpCallDetails(Api.GETPERAMETERS, "post", payload).then((data) => {
                this.showLoader = false;
                data.data.sort((a, b) => {
                    if (a.parameterId < b.parameterId) {
                        return -1;
                    }
                    if (a.parameterId > b.parameterId) {
                        return 1;
                    }
                    return 0;
                });
                this.rawParameterList = data.data;

                this.rawParameterList.forEach((parameter) => {
                    parameter.utility = this.utilityList.find((ele) => ele.utilityId === parameter.utilityId).utilityName;
                    parameter["consumption"] = this.tabList.find((ele) => ele.consumptionId === parameter.consumptionId).consumptionName;
                    parameter["showDetail"] = true;
                    const periodName = parameter["previousPeriodId"] ? this.periodList.find((ele) => ele.periodId === parameter["previousPeriodId"]).periodName : "";
                    parameter["previousPeriodName"] = periodName;
                    this.refIdByValue[parameter.ref2Id.toLowerCase()] = parameter.value ?? 0;
                    parameter["dataSource"] = parameter.dataSource ? parameter.dataSource : parameter.prevDataSource;
                    parameter["comments"] = parameter.comments ? parameter.comments : parameter.prevComments;                    
                });
                console.log(JSON.parse(JSON.stringify(this.rawParameterList)));
                this.tabSwitch(this.selectedConsumption);

                this.changingValue(0, {});
                this.rawParameterList.forEach((parameter) => {
                    if (parameter.value) {
                        console.log(this.formatNumberToenUS(parameter.value), parameter.value, parameter.ref2Id);
                        let newVal = 0;
                        if (!isNaN(parameter.value)) newVal = parameter.value;
                        else if (!isNaN(parameter.value.replaceAll(",", ""))) newVal = parameter.value.replaceAll(",", "");
                        parameter.rawValue = parameter.value;
                        parameter.value = this.formatNumberToenUS(newVal);
                    } else {
                        parameter.rawValue = parameter.value;
                        parameter.value = this.formatNumberToenUS(parameter.value);
                    }
                });
                this.checkStatus();
            });
        },
        formatNumberToenUS(val) {
            if (val) return Number(val).toLocaleString("en-US");
            return val;
        },
        saveDraft() {
            this.showLoader = true;
            let newPlantId = this.$route.query.stPlantId;
            let newYear = this.$route.query.styear;
            let payload = {
                plantId: newPlantId === undefined ? this.plantId.plantId : Number(newPlantId),
                year: newYear === undefined ? this.committedYr : newYear,
                parameters: this.rawParameterList.map((parameter) => {
                    let obj = {};
                    let valueToPass = 0;

                    if (parameter.rawValue && Number(parameter.rawValue)) valueToPass = Number(parameter.rawValue);
                    else if (parameter.rawValue === 0 || parameter.rawValue === "0") valueToPass = 0;
                    else if (parameter.rawValue && Number(parameter.rawValue.replaceAll(",", ""))) valueToPass = Number(parameter.value.replaceAll(",", ""));
                    else valueToPass = null;

                    obj = {
                        parameterId: parameter.parameterId,
                        value: valueToPass,
                        responsibleIndividual: parameter.individualName ?? "",
                        dataSource: parameter.dataSource ?? "",
                        comments: parameter.comments ?? ""
                    };
                    // else obj = {};
                    return obj;
                })
            };
            console.log("payload", payload);
            ApiCalls.httpCallDetails(Api.SAVEDRAFTOFENERGYLOSS, "post", payload).then((data) => {
                console.log("Draft Data", data);
                this.draftMsg = "Data is successfully drafted..! Would you like to submit the data?";
                this.showLoader = false;
                this.showDraftPopup = true;
                this.checkStatus();
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            });
        },
        submit() {
            let newPlantId = this.$route.query.stPlantId;
            let newYear = this.$route.query.styear;
            this.showLoader = true;
            let payload = {

                year: newYear === undefined ? this.committedYr : Number(newYear),
                readyForSubmit: false,
                plantId: newPlantId === undefined ? this.plantId.plantId : Number(newPlantId),

                layoutId: this.layoutId
            };
            ApiCalls.httpCallDetails(Api.SUBMITDRAFTOFENERGYLOSS, "post", payload).then((data) => {
                console.log("data", data);
                this.showLoader = false;
                this.checkList = [];
                this.checkList.push(data.data.validationData);

                if (data.data.validationErrorFlag === true) {
                    this.reqrdChecksPopUp = true;
                    this.reqrdChecksMsg = data.data.message;
                } else if (data.data.validationErrorFlag === false) {
                    this.checksPopUp = true;
                    this.checkListForElectricUse = data.data.validationData.filter((chkVal) => chkVal.refId === "57");
                    this.checkList = data.data.validationData;
                }
            });
        },
        submitData() {
            let newPlantId = this.$route.query.stPlantId;
            let newYear = this.$route.query.styear;
            this.showDraftPopup = false;
            let payload = {

                year: newYear === undefined ? this.committedYr : Number(newYear),

                plantId: newPlantId === undefined ? this.plantId.plantId : Number(newPlantId),
                layoutId: this.layoutId
            };
            ApiCalls.httpCallDetails(Api.ENABLESUBMISSIONBTN, "post", payload).then((data) => {
                if (data.data.missingParameterIds != null) {
                    if (data.data.missingParameterIds.length != 0) {
                        let missingRef = "";
                        data.data.missingParameterIds.forEach((missingPara) => {
                            missingRef += this.rawParameterList.find((para) => para.parameterId === missingPara).ref2Id + ", ";
                        });
                        missingRef = missingRef.substring(0, missingRef.length - 1);
                        this.errorMsg = `${missingRef.length ? "\n\n" : ""}   ${missingRef.substring(0, missingRef.length - 1)}`;

                        this.showPopup = true;
                    }
                } else {
                    this.submit();
                }
            });

        },

        proceedtoSubmit() {
            let newPlantId = this.$route.query.stPlantId;
            let newYear = this.$route.query.styear;
            this.showLoader = true;
            let payload = {
                year: newYear === undefined ? this.committedYr : Number(newYear),
                plantId: newPlantId === undefined ? this.plantId.plantId : Number(newPlantId),
                readyForSubmit: true,
                layoutId: this.layoutId
            };
            ApiCalls.httpCallDetails(Api.SUBMITDRAFTOFENERGYLOSS, "post", payload).then((data) => {
                console.log("data", data);
                this.showLoader = false;
                this.checksPopUp = false;
                this.showSucsPopup = true;
                this.sucstMsg = "Data has been successfully submitted.";
                this.checkStatus();
            });
        },
        showSuccessPopup() {
            this.showSucsPopup = false;
        },
        onClickNo() {
            this.showDraftPopup = false;
            this.statusMsg = "Draft";
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        },
        ClickNo() {
            this.checksPopUp = false;
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    },

    okonSaveDraft() {
        this.showPopupForDraftValues = false;
    }
};
</script>
<style scoped>
/* new css */
.title-with-Plant {
    font-size: 2.2rem;
    font-family: "BarlowSB", sans-serif;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
}

.input-field-container {
    width: 100%;
    display: flex;
    padding: 1.3rem;
    justify-content: space-between;
}

.parameter-name {
    color: #444;
    font: 1.16rem "BarlowM", sans-serif;
    margin: 2rem;
}

.input-field-div {
    display: flex;
    flex-direction: column;
    width: 22%;
}

.input-field-container div label,
.input-field-div label {
    color: #4161b2;
    font: 1.16rem "BarlowM", sans-serif;
}

.card-sub-container,
.card-container {
    border: 1px solid #d6dff833;
    min-height: 10rem;
}

.card-container:nth-child(even) {
    background: #f7f9ff;
}

.sub-card {
    margin-right: 2rem;
    display: flex;
    justify-content: space-between;
}

.sub-card label {
    font: 1.16rem "BarlowR", sans-serif;
    margin-right: 1rem;
}

.sub-card span {
    font: 1.16rem "BarlowM", sans-serif;
}

.btn-container {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem 0 0;
    padding: 0 2rem;
}

.btn {
    margin: 0 0rem;
    padding: 0 0rem;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    color: #274ba7 !important;
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
}

.save-btn:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.save-btn:hover {
    color: #fff !important;
    border-color: #869ed8 !important;
    background: #274ba7;
}

.save-btn {
    color: #fff !important;
    border-color: #869ed8 !important;
    background: #274ba7;
}

.action-desc:disabled {
    background: #e9ecef;
}

.hide-show-btn {
    font: 1.16rem "BarlowM", sans-serif;
    color: #274ba7;
}

#hide {
    rotate: 180deg;
}

.zero-padding-margin {
    padding: 0;
    margin: 0;
}

.custom-padding {
    padding: 0rem 2.3rem 0.6rem 2.1rem;
}

.prev-values {
    color: #5c5c5c !important;
    margin: 5px 0 0 0 !important;
    font: 1rem "BarlowM", sans-serif !important;
}

/* new css */
/* tabs */

.download-view {
    border-radius: 2rem;
    border: 1px solid #274ba7;

    font-size: 1.33rem;
}

.download-view:hover {
    background: #e3e8f7;
}

.action-btn {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    cursor: pointer;
}

.tab-titles {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-options {
    display: flex;
    margin-top: 2rem;
}

.tab-option-text {
    font: 1.16rem "BarlowR", sans-serif;
    cursor: pointer;
    font-weight: bold;
}

.in-action {
    margin-right: 1.688em;
}

.tab-active-option {
    font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
    border-top: 3px #274ba7 solid;
    /* width: 20%; */
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
    margin-top: 1rem;
}

.select-dd {
    width: 10%;
}

.button-dd {
    display: inline-flex;
}

.outer-block {
    background-color: #fff;
    border-radius: 6px;
}

.hr {
    position: relative;
    top: -26px;
}

/* tabs */
.plant-dd {
    width: 20.5%;
}

.main-con {
    background: #fff;
    margin-top: 0em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.reload-img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

div.fileinputs {
    position: relative;
    border: 1px solid #313131;
    border-radius: 4px;
    width: 100%;
}

div.fakefile {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.fakefileinput {
    height: 100%;
    width: 80%;
    border: transparent;
    border-right: 1px solid #313131;
    color: #313131;
    font: 1.166rem "BarlowR", sans-serif;
    padding: 5px;
    margin: 2px;
}

.fakefilespan {
    text-align: center;
    width: 20%;
    font: 1.166rem "BarlowSb", sans-serif;
    color: #274ba7;
}

input.file {
    position: relative;
    text-align: right;
    width: 100%;
    /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
    opacity: 0;
    z-index: 2;
}

.action-info-block {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
}

.no-padding {
    padding: 0;
}

.lable-text {
    z-index: 2;
}

.upload-button-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.pagination-con {
    display: flex;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.action-desc {
    border: #313131 1px solid;
    border-radius: 4px;
    padding: 1rem;
    resize: none;
}

#commentSection {
    font-family: "BarlowM", sans-serif;
    color: #313131 !important;
}

.calender-input {
    height: 52px;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

/* document.querySelector("#history-table-id > thead > tr > th:nth-child(1)") */
#history-table-id {
    border: 0px solid white !important;
    border-top: 1px solid #707070 !important;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1365px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1380px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (max-width: 1680px) and (min-width: 1381px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1921px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}
</style>